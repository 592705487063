import React from "react";
import ReactDOM from "react-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MDBIcon, MDBCol } from "mdbreact";
import "./shared.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import TableFilters from "./TableFilters";
import TableInlineEdits from "./TableInlineEdits";
import Modal from "../../../src/components/SharedComponents/Modal/Modal";
import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { convertLocalTime } from "../../Common/Helper";

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    var headings = this.props?.config?.headings;
    var colArr = {};
    var dataTypeArr = {};
    var currentSelectedRow = null;
    var selectOptionsArr = {};
    headings.forEach((heading, index) => {
      colArr[heading.headingLabel] = "";
      dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
      if (heading.inputType == "select")
        selectOptionsArr[this.props?.config?.dataNodes[index]] =
          heading.selectOptions;
    });
    this.state = {
      inputValues: colArr,
      filterValues: [],
      listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
      editInputValues: [],
      dataTypeValues: dataTypeArr,
      selectOptionValues: selectOptionsArr,
      contextMenu: null,
      showHistoryMenu: false,
      callLogId: null,
      rowClick: false,
    };
  }

  componentDidMount() {
    //var headings = this.props?.config?.headings;
    // var colArr = {};
    // var dataTypeArr = {};
    // var selectOptionsArr = {};
    // headings.forEach((heading) => {
    //     colArr[heading.headingLabel] = "";
    //     dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType ;
    //     if(heading.inputType == "select")
    //     selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
    // });
    // this.setState({
    //     inputValues : colArr,
    //     filterValues: [],
    //     listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
    //     dataTypeValues: dataTypeArr,
    //     selectOptionValues: selectOptionsArr
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
      });
      this.currentSelectedRow = null;
    }
  }

  addFilterChangeHandler = (index, name, value) => {
    var inputValues = this.state.inputValues;

    var filterValues = this.state.filterValues;
    inputValues[name] = "";
    filterValues.push(name + ": " + value);

    this.setState({
      inputValues: inputValues,
      filterValues: filterValues,
    });

    var filtersNone = false;
    if (filterValues.length == 0) filtersNone = true;
    this.props.applyFilter(filterValues, filtersNone);
  };

  filterChangeHandler = (index, name, value, filter) => {
    var inputValues = this.state.inputValues;
    inputValues[name] = value;
    this.setState({
      inputValues: inputValues,
    });
  };

  handleClose = () => {
    this.setState({ contextMenu: null });
  };

  handleInlineEditChange = (value, index, nodeName) => {
    var editInputValues = [...this.state.listEdits];
    editInputValues[index][nodeName] = value;
    this.setState({ listEdits: editInputValues });
  };

  handleFilterDelete = (removeNode, index) => {
    var filterValues = this.state.filterValues;
    filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues });
    var filtersNone = false;
    if (filterValues.length == 0) filtersNone = true;
    this.props.applyFilter(filterValues, filtersNone);
  };

  historyClick = (event) => {
    this.setState({
      showHistoryModal: true,
      showHistoryMenu: false,
      contextMenu: null,
    });
  };

  handleHistoryTooltipMenu = (event, dataItem) => {
    this.setState({ contextMenu: null }, () => {
      let name = ReactDOM.findDOMNode(this).parentNode.getAttribute("class");
      if (
        name.includes("landing-table-content") &&
        event.target.className.includes("MuiTableCell-root")
      ) {
        if (event.type == "contextmenu") {
          event.preventDefault();
          this.setState({
            contextMenu: {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            },
            showHistoryMenu: true,
            callLogId: dataItem.calllog_id,
          });
        } else if (event.type == "click") {
          this.currentSelectedRow = dataItem;
          this.onRowClick();
        }
      }
    });
  };

  onRowClick = () => {
    this.setState({ contextMenu: null });
    this.props.onRowClick(this.currentSelectedRow);
  };

  responseBtnClick(data) {
    this.setState({ contextMenu: null });
    this.props.onResponseClick(data);
  }
  closeBtnClick(data) {
    this.setState({ contextMenu: null });
    this.props.onCloseClick(data);
  }

  render() {
    return (
      <>
        <div>
          <div
            className={
              this.props?.maxHeight
                ? "tableContainer " + "max-height-" + this.props?.maxHeight
                : "tableContainer"
            }
          >
            <TableContainer
              component={Paper}
              className={
                this.props?.tableMode == "light"
                  ? "bg-white overflow-x-unset"
                  : "bg-light overflow-x-unset"
              }
            >
              <Table
                id={this.props.className + "-table"}
                aria-label="collapsible table"
                size="small"
                style={{ tableLayout: "fixed" }}
              >
                <colgroup>
                  {this.props?.config?.headings?.map((heading, index) => (
                    <col
                      key={"colWidth_" + index}
                      width={
                        heading.width
                          ? heading.width
                          : 100 / this.props?.config?.headings?.length + "%"
                      }
                    />
                  ))}
                </colgroup>
                <TableHead
                  id={this.props.className + "-tablehead"}
                  className={
                    this.props?.tableMode == "light" ? "bg-light" : "bg-dark"
                  }
                >
                  {this.props?.config?.filters ? (
                    <TableRow
                      className={
                        this.state.filterValues?.length > 0
                          ? "columnFilters d-block"
                          : "d-none"
                      }
                    >
                      {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
                      <div style={{ display: "flex" }}>
                        {this.state.filterValues &&
                          this.state.filterValues?.map((item, index) => (
                            <Chip
                              label={item}
                              onDelete={this.handleFilterDelete.bind(
                                this,
                                item,
                                index
                              )}
                            />
                          ))}
                      </div>
                      {/* </TableCell> */}
                    </TableRow>
                  ) : (
                    <></>
                  )}

                  <TableRow>
                    {this.props?.config?.headings?.map((heading, index) => (
                      <TableCell
                        key={this.props.className + "-" + index}
                        style={{ padding: "4px" }}
                        className={
                          this.props?.tableMode == "light"
                            ? "bg-light FW800"
                            : "bg-dark FW800"
                        }
                      >
                        {this.props?.config?.filters ? (
                          <>
                            <TableFilters
                              inputType={heading.inputType}
                              name={heading.headingLabel}
                              inputValue={
                                this.state.inputValues[heading.headingLabel]
                              }
                              filterChangeHandler={this.filterChangeHandler.bind(
                                this,
                                index,
                                heading.headingLabel
                              )}
                              addFilterChangeHandler={this.addFilterChangeHandler.bind(
                                this,
                                index,
                                heading.headingLabel
                              )}
                            />{" "}
                          </>
                        ) : (
                          <> </>
                        )}
                        <span
                          className="action-icon"
                          onClick={() =>
                            this.props?.sortCallback(heading.headingLabel)
                          }
                        >
                          <b>
                            {" "}
                            {heading.icon ? (
                              <div>
                                {heading.headingLabel}
                                <MDBIcon icon={heading.icon} />
                              </div>
                            ) : (
                              heading.headingLabel
                            )}{" "}
                          </b>
                        </span>
                      </TableCell>
                    ))}
                    {this.props?.config?.actions && (
                      <TableCell
                        key={
                          this.props.className +
                          "-" +
                          this.props?.config?.headings?.length
                        }
                        className={
                          this.props?.tableMode == "light"
                            ? "bg-light FW800"
                            : "bg-dark FW800"
                        }
                        style={{ padding: "4px" }}
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {this.props?.isLoading ? (
                  <div className="tableLoader">
                    <CircularProgress />
                  </div>
                ) : (
                  <TableBody key={this.props.className + "body"}>
                    {this.props?.list?.length > 0 &&
                      this.props?.list?.map((dataItem, rowIndex) => (
                        <React.Fragment>
                          <TableRow
                            id={
                              this.props.className +
                              dataItem[this.props.config.primaryKey]
                            }
                            onContextMenu={
                              this.props.handleShowMenu
                                ? (event) =>
                                    this.handleHistoryTooltipMenu(
                                      event,
                                      dataItem
                                    )
                                : ""
                            }
                            onClick={
                              this.props.handleShowMenu
                                ? (event) =>
                                    this.handleHistoryTooltipMenu(
                                      event,
                                      dataItem
                                    )
                                : ""
                            }
                            key={
                              this.props.className +
                              "-" +
                              dataItem[this.props.config.primaryKey] +
                              "-" +
                              rowIndex
                            }
                            className={`
                              ${
                                dataItem?.[this.props?.config?.primaryKey] ===
                                  this.currentSelectedRow?.[
                                    this.props?.config?.primaryKey
                                  ] && this.props.selectedRow
                                  ? "rowClick"
                                  : dataItem[this.props.config.rowColorFlag] ===
                                    1
                                  ? "showAsRed"
                                  : null
                              }`}
                          >
                            {this.props?.config?.dataNodes?.map((node, index) =>
                              node == "check" ? (
                                <TableCell
                                  className={
                                    this.props?.config?.rowColorNeeded &&
                                    dataItem[
                                      this.props.config?.rowColorFlag
                                    ] === true
                                      ? "urgent-row"
                                      : "urgent-row-false"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={dataItem[node]}
                                    onClick={(event, newValue) => {
                                      dataItem[node] = event.target.checked;
                                      this.props.arrayCheckUncheck(
                                        event.target.checked,
                                        dataItem
                                      );
                                    }}
                                  ></input>
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={
                                    this.props.className +
                                    "-" +
                                    dataItem[this.props?.config?.primaryKey] +
                                    "-" +
                                    dataItem[node] +
                                    "-" +
                                    index
                                  }
                                  className={
                                    this.props?.config?.changeColourToRed?.includes(
                                      dataItem[node]
                                    )
                                      ? "textColorRed"
                                      : this.props?.config?.changeColourToGreen?.includes(
                                          dataItem[node]
                                        )
                                      ? "textColorGreen"
                                      : ""
                                  }
                                >
                                  {dataItem["editing"] &&
                                  this.props?.config?.inlineEdits?.includes(
                                    node
                                  ) ? (
                                    <> </>
                                  ) : node == "groups" ? (
                                    dataItem[node]?.map((item) => (
                                      <div className="groupsStyles">{item}</div>
                                    ))
                                  ) : node == "label" ? (
                                    <div
                                      className="labelNames"
                                      onClick={(e) =>
                                        this.props.editData(dataItem, node)
                                      }
                                    >
                                      {dataItem[node]}
                                    </div>
                                  ) : this.props?.config?.hyperlinks?.includes(
                                      node
                                    ) ? (
                                    <a
                                      href="javascript:void(0);"
                                      onClick={(e) =>
                                        this.props.hyperLinkNavigationCallback(
                                          dataItem,
                                          node
                                        )
                                      }
                                    >
                                      {dataItem[node]}
                                    </a>
                                  ) : this.props?.config?.urgentCheck?.some(
                                      (configItem) => configItem.column === node
                                    ) &&
                                    dataItem[
                                      this.props.config?.rowColorFlag
                                    ] === 1 ? (
                                    <MDBIcon icon={"check"} />
                                  ) : this.props?.config?.toggleFields?.includes(
                                      node
                                    ) ? (
                                    dataItem[node] != null ? (
                                      <MDBIcon
                                        icon={
                                          dataItem[node] == true
                                            ? "check-circle"
                                            : "times"
                                        }
                                        className={
                                          dataItem[node] == true
                                            ? "text-success"
                                            : "text-dark"
                                        }
                                      />
                                    ) : (
                                      <> </>
                                    )
                                  ) : this.props?.config?.fileAttachments?.includes(
                                      node
                                    ) ? (
                                    <BasicButton
                                      variant="outlined"
                                      icon="paperclip"
                                      type="inline"
                                      inputType="file"
                                      id={
                                        this.props?.objectType +
                                        "-" +
                                        this.props?.name
                                      }
                                      uploadFile={this.props?.handleFileSelect}
                                    />
                                  ) : this.state.selectOptionValues?.[node] ? (
                                    <>
                                      {this.state.selectOptionValues?.[
                                        node
                                      ].map((item) => {
                                        if (item.value == dataItem[node])
                                          return <> {item.label} </>;
                                      })}
                                    </>
                                  ) : this.props?.config?.urgentCheck?.some(
                                      (configItem) => configItem.column === node
                                    ) &&
                                    dataItem[
                                      this.props.config?.rowColorFlag
                                    ] === 0 ? (
                                    ""
                                  ) : (
                                    <>{(node==='created_at' && convertLocalTime(dataItem[node])) || dataItem[node]}</>
                                  )}
                                  {this.props?.config?.dataNodeGroups?.[
                                    node
                                  ] ? (
                                    <>
                                      {this.props?.config?.dataNodeGroups?.[
                                        node
                                      ]?.map((item) =>
                                        dataItem["editing"] &&
                                        this.props?.config?.inlineEdits?.includes(
                                          item
                                        ) ? (
                                          <> </>
                                        ) : (
                                          <div>{dataItem[item]}</div>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <> </>
                                  )}

                                  {dataItem["editing"] &&
                                  this.props?.config?.inlineEdits?.includes(
                                    node
                                  ) ? (
                                    <TableInlineEdits
                                      nodeName={node}
                                      inputType={
                                        this.state.dataTypeValues[node]
                                      }
                                      inputValue={
                                        this.state.listEdits?.[rowIndex]?.[node]
                                      }
                                      index={rowIndex}
                                      handleInlineEditChange={this.handleInlineEditChange.bind(
                                        this
                                      )}
                                      selectOptions={
                                        this.state.selectOptionValues?.[node]
                                      }
                                    />
                                  ) : (
                                    <> </>
                                  )}

                                  {this.props?.config?.dataNodeGroups?.[
                                    node
                                  ] ? (
                                    <>
                                      {this.props?.config?.dataNodeGroups?.[
                                        node
                                      ]?.map((item) =>
                                        dataItem["editing"] &&
                                        this.props?.config?.inlineEdits?.includes(
                                          item
                                        ) ? (
                                          <TableInlineEdits
                                            nodeName={item}
                                            inputType={"text"}
                                            inputValue={
                                              this.state.listEdits?.[
                                                rowIndex
                                              ]?.[item]
                                            }
                                            index={rowIndex}
                                            handleInlineEditChange={this.handleInlineEditChange.bind(
                                              this
                                            )}
                                          />
                                        ) : (
                                          <> </>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <> </>
                                  )}
                                </TableCell>
                              )
                            )}
                            {this.props?.config?.actions && (
                              <TableCell
                                key={this.props.className + "action"}
                                className={"border-ver-1-secondary"}
                              >
                                {" "}
                                <div className="row">
                                  {this.props?.config?.actions?.map(
                                    (action, indexNo) => (
                                      <div
                                        key={
                                          this.props.className + "-" + indexNo
                                        }
                                        className="col-md padding-3"
                                        disabled={
                                          this.props?.tabValue === 1
                                            ? !dataItem?.is_edit
                                            : false
                                        }
                                        onClick={() =>
                                          action === "pen"
                                            ? dataItem["editing"]
                                              ? this.props?.saveCallback(
                                                  this.state.listEdits,
                                                  rowIndex,
                                                  dataItem[
                                                    this.props.config.primaryKey
                                                  ],
                                                  dataItem
                                                )
                                              : this.props?.editCallback(
                                                  dataItem[
                                                    this.props.config.primaryKey
                                                  ],
                                                  dataItem
                                                )
                                            : action === "plus"
                                            ? this.props?.handleAddCallback(
                                                dataItem[
                                                  this.props.config.primaryKey
                                                ]
                                              )
                                            : action === "trash-alt"
                                            ? this.props?.handleDelCallBack(
                                                dataItem[
                                                  this.props.config.primaryKey
                                                ],
                                                dataItem
                                              )
                                            : null
                                        }
                                      >
                                        {action === "view" ? (
                                          <a href="#">{action}</a>
                                        ) : action === "pen" &&
                                          dataItem["editing"] ? (
                                          <MDBIcon
                                            icon={"save"}
                                            color="primary"
                                            className="action-icon"
                                          />
                                        ) : (
                                          <MDBIcon
                                            icon={action}
                                            className="action-icon"
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </TableCell>
                            )}
                            {this.props?.config?.actionButtons && (
                              <TableCell
                                key={this.props.className + "action"}
                                className={
                                  this.props?.config?.rowColorNeeded &&
                                  dataItem[this.props.config?.rowColorFlag] ===
                                    true
                                    ? "urgent-row"
                                    : "urgent-row-false"
                                }
                              >
                                {" "}
                                <div className="row">
                                  {this.props?.config?.actionButtons?.map(
                                    (action) => (
                                      <MDBCol>
                                        {!dataItem.is_closed && (
                                          <BasicButton
                                            variant="contained"
                                            type="inline"
                                            text={action}
                                            onClick={() => {
                                              if (action === "Respond") {
                                                this.responseBtnClick(dataItem);
                                              } else {
                                                this.closeBtnClick(dataItem);
                                              }
                                            }}
                                          />
                                        )}
                                      </MDBCol>
                                    )
                                  )}
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      ))}
                    {this.state.showHistoryModal && (
                      <Modal
                        open={this.state.showHistoryModal || false}
                        modalName={"History"}
                        callLogId={this.state.callLogId}
                        comfirmModalTitle={"History"}
                        onClose={() => {
                          this.setState({ showHistoryModal: false });
                        }}
                      />
                    )}
                    {this.props?.list?.length <= 0 && (
                      <TableRow>
                        <TableCell
                          className="text-align-center"
                          colSpan={this.props?.config?.headings?.length}
                        >
                          <b>No results</b>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {this.state.showHistoryMenu ? (
              <Menu
                open={this.state.contextMenu !== null}
                onClose={this.handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  this.state.contextMenu !== null
                    ? {
                        top: this.state.contextMenu.mouseY,
                        left: this.state.contextMenu.mouseX,
                      }
                    : null
                }
              >
                <MenuItem className="historyMenu" onClick={this.historyClick}>
                  Message History
                </MenuItem>
              </Menu>
            ) : (
              // "<Menu
              //   open={this.state.contextMenu !== null}
              //   onClose={this.handleClose}
              //   anchorReference="anchorPosition"
              //   anchorPosition={
              //     this.state.contextMenu !== null
              //       ? {
              //           top: this.state.contextMenu.mouseY,
              //           left: this.state.contextMenu.mouseX,
              //         }
              //       : null
              //   }
              // >
              //   <MenuItem
              //     className="editCall"
              //     onClick={() => this.onRowClick()}
              //   >
              //     edit call
              //   </MenuItem>
              // </Menu>
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default TableComponent;
