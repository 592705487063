import React from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBContainer,
  MDBNavbarLink,
  MDBDropdownLink,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { fade, makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import logo from "../assets/logo.png";
import logo_new from "../assets/logo_new.png";
import { withOktaAuth } from "@okta/okta-react";
import { withRouter } from "react-router-dom";
import AsynchronousSearch from "../../src/components/SharedComponents/AsynchronousSerach/AsynchronousSearch";
import { withUserContext } from "../contexts/UserContext";
import MessageModal from "../components/SharedComponents/MessageModal";
import Modal from "../components/SharedComponents/Modal/Modal";
import * as appConstants from "../constants/appConstants";
// import { contactDetails } from "../home/LandingPage/Config";
import PhoneLogService from "../services/service";
import * as Constants from "../constants/constants";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./HeaderContent.scss";
import { AppService } from "../services/AppService";
import { CircularProgress } from "@material-ui/core";

export default //withRouter(
withUserContext(
  withOktaAuth(
    class HeaderContent extends React.Component {
      constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.state = {
          confirmSaveChanges: false,
          switch_tenant_id: 0,
          switch_tenant_name: "",
          isManageUser: false,
          showManageLookupModal: false,
          showContactListModal: false,
          hasNewLogs: false,
          showBellIconNotification: false,
          contextMenu: null,
          loadingPDF: false,
        };
      }

      async logout() {
        var len = localStorage.length;
        var keys = Object.keys(localStorage);
        for (var i = 0; i < len; i++) {
          var key = keys[i];
          if (key.indexOf("okta-") == -1) localStorage.removeItem(key);
        }

        this.props.oktaAuth.signOut("/");
      }

      async login() {
        // Redirect to '/' after logout
        this.props.authService.login("/");
      }
      handleSideNavActive = (value, routePath) => {
        this.props.history.push(routePath, this.state);
      };

      switchTenant = (tenant_id, tenant_name) => {
        this.setState({
          switch_tenant_id: tenant_id,
          switch_tenant_name: tenant_name,
          confirmSaveChanges: !this.state.confirmSaveChanges,
        });
      };

      confirmCancelChanges = (isCancel) => {
        //if (isCancel) {
        this.props.userContext.switchTenant(this.state.switch_tenant_id);
        //}
        //else {
        this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
        //}
      };
      closeNotification = () => {
        this.setState({ showBellIconNotification: false, hasNewLogs: false });
        AppService.refreshPhoneLogs();
      };
      componentDidMount() {
        if (this.props?.userContext?.user_profile?.is_user_provisioner !== 1) {
          this.myTimeout = setInterval(() => {
            PhoneLogService.getDataWithPath(
              Constants.phoneLogServiceBaseUrl +
                "/checkfornewmessages?userId=" +
                this.props?.userContext?.user_profile?.user_id,
              this.props?.userContext?.user_profile?.tenant_id
            ).then(
              (response) => {
                if (response.data[0].count > 0) {
                  this.setState({ hasNewLogs: true });
                } else {
                  this.setState({ hasNewLogs: false });
                }
              },
              (err) => {
                this.setState({ hasNewLogs: false });
                console.log("Error in fetching user contacts", err);
              }
            );
          }, 40000);
        }
      }
      componentWillReceiveProps(nextProps) {
        this.setState({
          isManageUser:
            nextProps.userContext?.active_tenant?.permissions?.isManageUser,
        });
      }

      toggleManageLookupVisibility = () => {
        this.setState({
          showManageLookupModal: !this.state.showManageLookupModal,
        });
      };

      toggleContactListModal = () => {
        this.setState({
          toggleContactListModal: !this.state.toggleContactListModal,
        });
        if (!this.state.toggleContactListModal) {
          this.props?.getUserContacts(this.props?.currentGroupMember?.user_id);
        }
      };
      saveContact = (contactDetails) => {
        this.props.userContext.setAppLoader(true);
        PhoneLogService.postDataParams(
          Constants.phoneLogServiceBaseUrl + "/postcontactdetails ",
          contactDetails,
          this.props?.userContext?.user_profile?.tenant_id
        ).then(
          (response) => {
            this.props.userContext.setAppLoader(false);
            if (response.data.error) {
              AppService.showMessageModal("fail");
            } else {
              AppService.showMessageModal("success");
              // this.props.userContext?.getUserContacts();
              // this.toggleContactListModal();
            }
          },
          (err) => {
            this.props.userContext.setAppLoader(false);
            AppService.showMessageModal("fail");
            console.log("Error in saving CallDetails", err);
          }
        );
      };
      getNotifications = (event) => {
        if (event.type == "click") {
          event.preventDefault();
          if (this.state.hasNewLogs) {
            this.setState({
              contextMenu: {
                mouseX: event.clientX - 100,
                mouseY: event.clientY + 15,
              },
              showBellIconNotification: true,
            });
          }
        }
      };

      downloadUrl = (url, reportName) => {
        window.open(url, "_blank");
      };

      downloadUserManualPDF = () =>{
        this.setState({ loadingPDF: true });
        let fileName = "PhoneLog-UserManual.pdf";
        PhoneLogService.getDataParams(
          Constants.phoneLogServiceBaseUrl +
            "/downloadfile?fileName=" +
            fileName,
          "",
          this.props.userContext?.active_tenant?.tenant_id
        ).then(
          (response) => {
            this.downloadUrl(response.data, "Show");
            this.setState({ loadingPDF: false });
          },
          (err) => {
            this.setState({ loadingPDF: false });
          }
        );
      };

      render() {
        const bgColorBlue = { backgroundColor: "#0062AF" };
        const adminTitle = {
          fontSize: "13px",
          color: "#ffffff",
          padding: "2px",
        };
        const ptli = { paddingTop: "0.1rem" };

        return (
          <div id="headerContent">
            {this.state.showManageLookupModal && (
              <Modal
                open={this.state.showManageLookupModal || false}
                modalName={"ManageLookup"}
                comfirmModalTitle={"Lookups"}
                onClose={this.toggleManageLookupVisibility}
              />
            )}

            {this.state.toggleContactListModal && (
              <Modal
                open={this.state.toggleContactListModal || false}
                modalName={"ContactList"}
                comfirmModalTitle={"Contact List"}
                mode={appConstants.LABEL_FIND}
                userData={this.props?.userContext.userContacts}
                phoneTypeList={this.props?.userContext.phoneTypes}
                currentGroupMember={this.props.currentGroupMember}
                onClose={this.toggleContactListModal}
                onSave={this.saveContact}
                updateUserContacts={this.props?.updateUserContacts}
                getUserContacts={this.props?.getUserContacts}
                currentContactListHeader={this.props?.currentContactListHeader}
              />
            )}

            {/* <Router fixed="top"> */}
            <header>
              <div className="fixed-top">
                <MDBNavbar bgColor="primary" dark expand="md" fixed sticky>
                  <MDBContainer fluid>
                    <MDBNavbarBrand className="mb-0 h1">
                      {/* <MDBNavbarLink tag={Link} active={false} className="nav-link-override" to="/" > */}
                      <Link
                        component={RouterLink}
                        className="action-icon"
                        to={"/"}
                      >
                        <img
                          src={logo_new}
                          alt="Logo"
                          className="imageWidth"
                          //onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                        />
                      </Link>
                      {/* </MDBNavbarLink> */}
                    </MDBNavbarBrand>
                    <h1 className="app-title">PHONE LOG</h1>
                    <MDBNavbarNav fullWidth={false} className="mb-2 mb-lg-0">
                      {" "}
                      {/* <MDBNavbarItem className="d-none d-lg-block">
                                            <span style={adminTitle}>Signed in as: &nbsp;
                                            <b> {this.props.user_full_name} </b>
                                                <br />
                                                <i> {this.props.active_tenant_role ? this.props.active_tenant_role + ", " + this.props.active_tenant_name : ""} </i>
                                            </span>
                                        </MDBNavbarItem> */}
                      {this.props?.userContext?.active_tenant?.permissions
                        ?.isAdmin &&
                        window.location.pathname === "/" && (
                          <>
                            <MDBIcon
                              fas
                              size="2x"
                              icon="bell"
                              className={`mr-2 shadow-none text-white padding-hor-25 padding-ver-7 ${
                                this.state.hasNewLogs ? "bgColorRed" : ""
                              }`}
                              onClick={(event) => this.getNotifications(event)}
                            />
                            <MDBIcon
                              fas
                              size="2x"
                              icon="bars"
                              className="mr-2 bgColorBlue shadow-none text-white padding-hor-25 padding-ver-7"
                              onClick={() =>
                                this.toggleManageLookupVisibility()
                              }
                            />
                          </>
                        )}
                      {!this.state?.isManageUser &&
                        this.props?.userContacts && (
                          <MDBIcon
                            fas
                            size="2x"
                            icon="address-book"
                            className="mr-2 bgColorBlue shadow-none text-white padding-hor-25 padding-ver-7"
                            onClick={() => this.toggleContactListModal()}
                          />
                        )}
                      {/* <MDBIcon fas size='2x' icon="user-cog" className="mr-2 bgColorBlue shadow-none text-white padding-hor-25 padding-ver-7" /> */}
                      <MDBNavbarItem className="padding-hor-25">
                        <MDBDropdown>
                          <MDBDropdownToggle
                            tag="a"
                            className="bgColorBlue shadow-none text-white angle-down"
                          >
                            <MDBIcon
                              fas
                              size="3x"
                              icon="angle-down"
                              className="mr-2"
                            />
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="text-end border border-1-quinary">
                            {this.props.userContext?.user_profile?.user_roles?.map(
                              (tenant) => {
                                if (
                                  tenant.tenant_id !=
                                  this.props.userContext?.active_tenant
                                    ?.tenant_id
                                )
                                  return (
                                    <MDBDropdownItem
                                      data-active={false}
                                      key={tenant.tenant_id}
                                      onClick={this.switchTenant.bind(
                                        this,
                                        tenant.tenant_id,
                                        tenant.tenant_name
                                      )}
                                    >
                                      <MDBDropdownLink
                                        className="text-tertiary"
                                        active={false}
                                        to="/"
                                      >
                                        <small>
                                          {
                                            this.props.userContext?.user_profile
                                              ?.user_name
                                          }
                                        </small>
                                      </MDBDropdownLink>
                                      <MDBDropdownLink
                                        className="text-tertiary"
                                        active={false}
                                        to="/"
                                      >
                                        <small>{tenant.role_name}</small>
                                      </MDBDropdownLink>
                                    </MDBDropdownItem>
                                  );
                                else
                                  return (
                                    <MDBDropdownItem
                                      data-active={false}
                                      key={tenant.tenant_id}
                                    >
                                      <div className="text-tertiary m-2">
                                        {
                                          this.props.userContext?.user_profile
                                            ?.full_name
                                        }
                                      </div>
                                      <div className="text-tertiary m-2">
                                        {tenant.role_name}
                                      </div>
                                    </MDBDropdownItem>
                                  );
                              }
                            )}

                            <MDBDropdownItem
                              active={false}
                              onClick={this.downloadUserManualPDF}
                            >
                              <MDBDropdownLink active={false} to="/">
                                {this.state.loadingPDF ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                  />
                                ) : (
                                  "User Manual PDF"
                                )}
                              </MDBDropdownLink>
                            </MDBDropdownItem>

                            <MDBDropdownItem
                              active={false}
                              className={
                                !this.props.isLoggedIn ? "hide" : "show"
                              }
                              disabled={!this.props.isLoggedIn}
                              onClick={this.logout}
                            >
                              <MDBDropdownLink
                                className="fw-bold"
                                active={false}
                                to="/"
                              >
                                Logout
                              </MDBDropdownLink>
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </MDBNavbarItem>
                    </MDBNavbarNav>
                  </MDBContainer>
                </MDBNavbar>
                <MessageModal
                  open={this.state.confirmSaveChanges}
                  title={"Unsaved Changes"}
                  message={
                    "Switching tenant will lose any unsaved changes. Do you want to proceed?"
                  }
                  primaryButtonText={"Yes"}
                  secondaryButtonText={"No"}
                  ononfirm={() => this.confirmCancelChanges()}
                  handleClose={(e) =>
                    this.setState({ confirmSaveChanges: false })
                  }
                />
              </div>
              {this.state.showBellIconNotification ? (
                <Menu
                  open={this.state.contextMenu !== null}
                  onClose={this.handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    this.state.contextMenu !== null
                      ? {
                          top: this.state.contextMenu.mouseY,
                          left: this.state.contextMenu.mouseX,
                        }
                      : null
                  }
                >
                  <MenuItem
                    className="bellIconNotification"
                    onClick={this.closeNotification}
                  >
                    <div class="content">
                      <MDBRow className="icon-with-text">
                        <MDBCol size={1}>
                          <MDBIcon size="2x" icon="bell"></MDBIcon>
                        </MDBCol>
                        <MDBCol size={8}>
                          <span>New messages have been added</span>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="only-text">
                        <MDBCol size={1}></MDBCol>
                        <MDBCol size={8}>
                          <span>Click here to load, or refresh the page.</span>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MenuItem>
                </Menu>
              ) : (
                ""
              )}
            </header>
            {/* </Router> */}
          </div>
        );
      }
    }
  )
);
